var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',{staticClass:"clapans"},[_c('CCol',{attrs:{"md":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"d-flex aligin-items-center justify-content-between"},[_c('span',[_vm._v("История клапана: "+_vm._s(_vm.id))])])]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light"},scopedSlots:_vm._u([{key:"select",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CInputCheckbox',{attrs:{"value":item.select,"custom":"","inline":""},on:{"change":function($event){_vm.isSelect = true}}})],1)}},{key:"city",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.city)+" ")])}},{key:"district",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.district)+" ")])}},{key:"street",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.street)+" ")])}},{key:"building",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.typeofbuilding)+" ")])}},{key:"numHouse",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.buildingnum)+" ")])}},{key:"numDoor",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.entrancenum)+" ")])}},{key:"numMine",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.shaftnum)+" ")])}},{key:"stage",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.floor)+" ")])}},{key:"numOffice",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.aptoroffice)+" ")])}},{key:"id",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.valveID)+" ")])}},{key:"status",fn:function(ref){
var item = ref.item;
return _c('td',{},[(item.status==0)?_c('CBadge',{attrs:{"color":"danger"}},[_vm._v(" выключен ")]):(item.status==1)?_c('CBadge',{attrs:{"color":"success"}},[_vm._v("включен")]):(item.status==2)?_c('CBadge',{attrs:{"color":"warning"}},[_vm._v("нет данных")]):_c('CBadge',{attrs:{"color":"info"}},[_vm._v("не известно")]),_c('br'),_c('CSwitch',{staticClass:"mt-1",attrs:{"color":"primary","checked":item.status==1,"value":item.status},on:{"update:checked":[function($event){return _vm.$set(item, "status==1", $event)},function($event){return _vm.actionStatusChange(item)}]}})],1)}},{key:"settings",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"white-space":"nowrap","cursor":"pointer"},on:{"click":function($event){_vm.warningModal = !_vm.warningModal}}},[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cilSettings"}}),_vm._v(" Настройки ")],1),_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"white-space":"nowrap","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({path: '/dashboard/'+item.id})}}},[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cilSettings"}}),_vm._v(" История ")],1)])}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }